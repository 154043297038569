/* eslint-disable no-mixed-spaces-and-tabs */
// TODO - Type 'any' needs to be fixed.
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
	Appointments,
	Scheduler,
	WeekView,
	// AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { subMinutes } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useMediaQuery } from "react-responsive";

import { JOB, SKILLS } from "../../assets/constants";
import Error from "../../components/Error";
import AppointmentContainer from "./AppointmentContainer";
import AppointmentContent from "./AppointmentContent";

const useStyles = makeStyles((theme: any) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: "#303030",
	},
	mainContent: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
		// padding: theme.spacing(3),
	},
	drawer: {
		width: 240,
		flexShrink: 0,
	},
	drawerOpen: {
		width: 240,
		flexShrink: 0,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		width: 40,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
	},
	drawerPaper: {
		width: 240,
	},
	drawerContainer: {
		overflow: "auto",
	},
	todayButton: {
		borderColor: "#fff",
		color: "#fff",
	},
	weekNavButtons: {
		color: "#FFF",
	},
	drawerCollapseBox: {
		backgroundColor: "#303030",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 200,
		color: "#fff",
	},
	today: {
		backgroundColor: alpha(theme.palette.primary.main, 0.16),
	},
	todayCell: {
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		"&:hover": {
			backgroundColor: alpha(theme.palette.primary.main, 0.14),
		},
		"&:focus": {
			backgroundColor: alpha(theme.palette.primary.main, 0.16),
		},
	},
}));

function getDayNumber(dayName: string) {
	const days: Record<string, number> = {
		monday: 1,
		tuesday: 2,
		wednesday: 3,
		thursday: 4,
		friday: 5,
		saturday: 6,
		sunday: 7,
	};
	if (!dayName) return 1;
	return days[dayName.toLowerCase()];
}
const TimeTableCell = ({ openCreateNewShiftDialog, ...props }: any) => {
	return <WeekView.TimeTableCell {...props} />;
};

const DayScaleCell = (props: any) => {
	const classes = useStyles();
	const { startDate, today } = props;

	if (today) {
		return <WeekView.DayScaleCell {...props} className={classes.today} />;
	}
	if (startDate.getDay() === 0 || startDate.getDay() === 6) {
		return (
			<WeekView.DayScaleCell {...props} className={classes.weekNavButtons} />
		);
	}
	return <WeekView.DayScaleCell {...props} />;
};

function AvailabilityMap({
	shiftsByFilter,
	value,
	changeWeekAvailabilityStatus,
	height,
}: any) {
	const [formattedData, setFormattedData] = useState([]);
	//const classes = useStyles();
	const [isDrawerCollapsed, setIsDrawerCollapsed] = useState(false);

	const weekStartTime = useSelector(
		(state: any) => state.user?.userData?.weekStartTime
	);

	const weekStartDay = useSelector(
		(state: any) => state.user?.userData?.weekStartDay
	);

	// const toggleDrawerCollapseState = () =>
	// 	setIsDrawerCollapsed(!isDrawerCollapsed);

	useMediaQuery({ maxWidth: 1224 }, undefined, () =>
		setIsDrawerCollapsed(!isDrawerCollapsed)
	);

	// const fetchInitialDataStatus = useSelector(
	//     (state: any) => state.supervisor?.fetchInitialData?.status
	// );

	const fetchFilterAndShiftDataStatus = useSelector(
		(state: any) => state.supervisor?.fetchFilterAndShiftData?.status
	);

	const getHourlyWorkerCountStatus = useSelector(
		(state: any) => state.supervisor?.getHourlyWorkerCount?.status
	);

	const fetchInitialDataErrorMessage = useSelector(
		(state: any) => state.supervisor?.fetchInitialData?.errorMessage
	);

	const allAvailabilityByJob = useSelector(
		(state: any) => state.supervisor.masterData.availabilityDataByJob
	);

	const allAvailabilityBySkill = useSelector(
		(state: any) => state.supervisor.masterData.availabilityDataBySkill
	);

	// const allJobRoles = useSelector(
	//     (state: any) => state.supervisor.masterData.allJobRoles
	// );
	//
	// const allSkills = useSelector(
	//     (state: any) => state.supervisor.masterData.allSkills
	// );

	const allJobRoles = useSelector(
		(state: any) => state.supervisor.filterData.allJobRoles
	);
	const allSkills = useSelector(
		(state: any) => state.supervisor.filterData.allSkills
	);

	const currentDate = useSelector(
		(state: any) => state.supervisor?.schedulerData?.currentDate
	);

	useEffect(() => {
		if (value !== 1) {
			if (shiftsByFilter === JOB) {
				if (allAvailabilityByJob) {
					const filteredAvailabilityByJob = allAvailabilityByJob.filter(
						(availabilityByJob: any) =>
							availabilityByJob.entityId === parseInt(value)
					);
					const dataByJob = filteredAvailabilityByJob.map((itemByJob: any) => {
						const resByJob = allJobRoles.find(
							(job: any) => job.id === itemByJob.entityId
						);
						const endDateByJob = new Date(itemByJob.endDate);
						const newItemByJob = { ...itemByJob };
						newItemByJob.endDate = subMinutes(endDateByJob, 1).toISOString();
						return { ...resByJob, ...newItemByJob };
					});

					const newFormattedDataByJob = dataByJob.map(function (item: any) {
						delete item.id;
						return item;
					});
					// Using maximum number of workers instead of max worker
					const maxNumberOfWorkers =
						newFormattedDataByJob.length > 0
							? Math.max(
									...newFormattedDataByJob.map(
										(item: any) => item.numberOfWorkers
									)
							  )
							: 0;
					const finalFormattedDataByJob = newFormattedDataByJob.map(
						(item: any) => {
							return {
								...item,
								maxWorkers: maxNumberOfWorkers,
							};
						}
					);
					setFormattedData(finalFormattedDataByJob);
				}
			} else if (shiftsByFilter === SKILLS) {
				if (allAvailabilityBySkill) {
					const filteredAvailabilityBySkill = allAvailabilityBySkill.filter(
						(availabilityBySkill: any) =>
							availabilityBySkill.entityId === parseInt(value)
					);
					const dataBySkill = filteredAvailabilityBySkill.map(
						(itemBySkill: any) => {
							const resBySkill = allSkills.find(
								(skill: any) => skill.id === itemBySkill.entityId
							);
							const endDateBySkill = new Date(itemBySkill.endDate);
							const newItemBySkill = { ...itemBySkill };
							newItemBySkill.endDate = subMinutes(
								endDateBySkill,
								1
							).toISOString();
							return { ...resBySkill, ...newItemBySkill };
						}
					);

					const formattedDataBySkill = dataBySkill.map(function (item: any) {
						delete item.id;
						return item;
					});
					// Using maximum number of workers instead of max worker
					const maxNumberOfWorkers =
						formattedDataBySkill.length > 0
							? Math.max(
									...formattedDataBySkill.map(
										(item: any) => item.numberOfWorkers
									)
							  )
							: 0;
					const finalFormattedDataBySkill = formattedDataBySkill.map(
						(item: any) => {
							return {
								...item,
								maxWorkers: maxNumberOfWorkers,
							};
						}
					);
					setFormattedData(finalFormattedDataBySkill);
				}
			}
		}
	}, [
		value,
		shiftsByFilter,
		allAvailabilityByJob,
		allAvailabilityBySkill,
		allJobRoles,
		allSkills,
	]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: height,
			}}
		>
			{fetchFilterAndShiftDataStatus === "error" && (
				<Error errorMessage={fetchInitialDataErrorMessage} />
			)}
			{getHourlyWorkerCountStatus === "error" && (
				<Error errorMessage={fetchInitialDataErrorMessage} />
			)}
			{(fetchFilterAndShiftDataStatus === "pending" ||
				getHourlyWorkerCountStatus === "pending") && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						flex: 1,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							flex: 1,
						}}
					>
						<CircularProgress />
						<Box mt={1}>
							<Typography>Loading...</Typography>
						</Box>
					</Box>
				</Box>
			)}
			{fetchFilterAndShiftDataStatus === "fulfilled" &&
				getHourlyWorkerCountStatus !== "pending" &&
				getHourlyWorkerCountStatus !== "error" && (
					<>
						{changeWeekAvailabilityStatus === "pending" ? (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									flex: 1,
								}}
							>
								<CircularProgress />
								<Box mt={1}>
									<Typography>Loading...</Typography>
								</Box>
							</Box>
						) : (
							<Box key={`scheduler-${shiftsByFilter}`}>
								<Scheduler
									data={formattedData}
									firstDayOfWeek={getDayNumber(weekStartDay)}
									height={height - 12}
								>
									<ViewState currentDate={currentDate} />
									<WeekView
										timeTableCellComponent={TimeTableCell}
										dayScaleCellComponent={DayScaleCell}
										cellDuration={60}
									/>
									<Appointments
										appointmentComponent={AppointmentContainer}
										appointmentContentComponent={AppointmentContent}
									/>
								</Scheduler>
							</Box>
						)}
					</>
				)}
		</Box>
	);
}

export default AvailabilityMap;
