/* eslint-disable simple-import-sort/imports */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserLocations } from "../../../../js/features/ManageWorkers/ManageWorkersSlice";
import {
	fetchskilllevels,
	getWorkerInsightReport,
} from "../../insight-dashboard/slices/worker-insight-report-slice";
import crossSkillingDashboardControllerData from "./use-cross-skilling-dashboard-data";
import { getSkillInsightReport } from "../../insight-dashboard/slices/skill-insight-report-slice";
import {
	Location,
	SkillInsightModel,
	WorkerInsightModel,
	columnType,
} from "../../insight-dashboard/slices/insight-report-types";
import { getSkillPriorities } from "../../skills/store/skills-slice";

const useCrossSkillingDashboardController = () => {
	const {
		workersReportData,
		fetchWorkerInsightReportStatus,
		allLocations,
		allSkillLevels,
		skillsReportData,
		fetchSkillInsightReportStatus,
		allPriorities,
		isSkillPriorityEnabled,
	} = crossSkillingDashboardControllerData();

	const dispatch = useDispatch();

	const [selectedLocations, setSelectedLocations] = useState<Array<string>>([]);
	const [searchText, setSearchText] = useState("");
	const [filteredWorkerSummariesData, setFilteredWorkerSummariesData] =
		useState(null);
	const [filteredSkillSummariesData, setFilteredSkillSummariesData] =
		useState(null);
	const [selectedKPI, setKpi] = useState<
		| "skillsWithLessThenXWorkersKPI"
		| "interestedWorkersKPI"
		| "workersWithLessThenXSkillsKPI"
		| "workersWithAllSkillsKPI"
		| "overallWorkers"
	>("overallWorkers");
	const [overAllWorkersData, setOverallWorkersData] =
		useState(workersReportData);
	const [overAllSkillsData, setOverallSkillsData] = useState(skillsReportData);

	const [overAllColumns, setOverAllColumns] = useState<columnType[]>([]);

	const [kpiCount, setKpiCount] = useState({
		skillsWithLessThenXWorkersKPI: 0,
		interestedWorkersKPI: 0,
		workersWithLessThenXSkillsKPI: 0,
		workersWithAllSkillsKPI: 0,
	});

	useEffect(() => {
		dispatch(getWorkerInsightReport());
		dispatch(fetchUserLocations());
		dispatch(fetchskilllevels());
		dispatch(getSkillPriorities());
		dispatch(getSkillInsightReport());
		dispatch(fetchUserLocations());
	}, [dispatch]);

	useEffect(() => {
		const locations = allLocations.map((item: Location) => item.name);
		setSelectedLocations(locations);
	}, [allLocations]);

	useEffect(() => {
		setKpi("overallWorkers");

		if (selectedLocations.length == 0) {
			setKpiCount({
				skillsWithLessThenXWorkersKPI: 0,
				interestedWorkersKPI: 0,
				workersWithLessThenXSkillsKPI: 0,
				workersWithAllSkillsKPI: 0,
			});
		}
	}, [selectedLocations]);

	useEffect(() => {
		const workersColumns = [
			{ checked: true, field: "workerName", headerName: "Worker Name" },
			{ checked: true, field: "workerLocation", headerName: "Worker Location" },
			{ checked: true, field: "workcenter", headerName: "Workcenter" },
			{ checked: false, field: "primaryJob", headerName: "Primary Job" },
			{ checked: false, field: "joiningDate", headerName: "Joining Date" },
			{ checked: true, field: "totalSkills", headerName: "Total Skills" },

			{
				checked: true,
				field: "skillScore",
				headerName: "Total Skill Score",
			},
			{
				checked: true,
				field: "interestedSkills",
				headerName: "Interested Skills",
			},
			{ checked: true, field: "workerBackup", headerName: "Worker Backup" },
		];

		const skillsColumns = [
			{ checked: true, field: "skillName", headerName: "Skill Name" },
			{
				checked: true,
				field: "skillPriority",
				headerName: "Skill Priority",
			},
			{
				checked: true,
				field: "interestedWorkersCount",
				headerName: "Interested Workers",
			},
			{
				checked: true,
				field: "totalWorkers",
				headerName: "Workers with Skill",
			},
		];
		if (
			selectedKPI === "skillsWithLessThenXWorkersKPI" ||
			selectedKPI === "interestedWorkersKPI"
		) {
			const skillColumns = isSkillPriorityEnabled
				? skillsColumns
				: skillsColumns.filter((item) => item.field !== "skillPriority");
			setOverAllColumns(skillColumns);
		} else {
			const workerInsightColumns = isSkillPriorityEnabled
				? workersColumns
				: workersColumns.filter((item) => item.field !== "skillScore");
			setOverAllColumns(workerInsightColumns);
		}
	}, [selectedKPI, isSkillPriorityEnabled]);

	const [workersWithLessThenXSkills, setworkersWithLessThenXSkills] = useState<
		WorkerInsightModel[]
	>([]);

	const [workersWithAllSkills, setWorkersWithAllSkills] =
		useState(workersReportData);

	const [skillsWithLessThanXWorkers, setskillsWithLessThanXWorkers] = useState<
		SkillInsightModel[]
	>([]);

	useEffect(() => {
		const workersWithLessThenXSkillsData: WorkerInsightModel[] = [];

		const countObj = kpiCount;

		//Filter by locations
		if (selectedLocations.length === 0) {
			setworkersWithLessThenXSkills(workersWithLessThenXSkillsData);
			setOverallWorkersData(workersReportData);

			return;
		}
		let filteredData: WorkerInsightModel[] = [];

		if (selectedLocations?.length === allLocations.length) {
			filteredData = [...workersReportData];
		} else {
			workersReportData
				.filter((worker) => selectedLocations.includes(worker.locationName))
				.map((item) => {
					const workerData = { ...item };
					workerData.workerBackupList = item.workerBackupList?.filter(
						(worker) => selectedLocations.includes(worker.location)
					);
					filteredData.push(workerData);
				});
		}

		setOverallWorkersData(filteredData);

		filteredData.forEach((item) => {
			let totalSkillsCount = 0;
			item.workerSkillCountByLevel.forEach((skill) => {
				const lev = allSkillLevels.find((level) => level.level === skill.level);
				if (lev && lev.isQualifiedAsSkilled) {
					totalSkillsCount += skill.count;
				}
			});

			if (totalSkillsCount < 3) {
				workersWithLessThenXSkillsData.push(item);
			}
		});

		if (workersWithLessThenXSkillsData.length === 0) {
			const sortedWorkerData: WorkerInsightModel[] = [...filteredData].sort(
				(a, b) => {
					const sumA = a.workerSkillCountByLevel.reduce(
						(acc: any, obj: any) => acc + obj.count,
						0
					);
					const sumB = b.workerSkillCountByLevel.reduce(
						(acc: any, obj: any) => acc + obj.count,
						0
					);
					return sumB - sumA;
				}
			);
			countObj.workersWithLessThenXSkillsKPI = 0;
			setworkersWithLessThenXSkills(sortedWorkerData);
		} else {
			countObj.workersWithLessThenXSkillsKPI =
				workersWithLessThenXSkillsData.length;
			setworkersWithLessThenXSkills(workersWithLessThenXSkillsData);
		}
		setKpiCount(countObj);
	}, [
		workersReportData,
		selectedLocations,
		allLocations,
		kpiCount,
		allSkillLevels,
	]);

	useEffect(() => {
		const skillsWithLessThanXWorkersData: SkillInsightModel[] = [];
		const countObj = kpiCount;

		//Filter by location
		const selectedLocationWithIds: Location[] = [];

		selectedLocations?.map((location: string) => {
			const data = allLocations.find((x: Location) => x.name === location);
			selectedLocationWithIds.push({
				id: data.id,
				name: data.name,
				isActive: data.isActive,
			});
		});

		if (selectedLocationWithIds.length === 0) {
			setskillsWithLessThanXWorkers(skillsWithLessThanXWorkersData);
			setOverallSkillsData(skillsReportData);
			return;
		}

		let filtereSkillsdDataByLoc: SkillInsightModel[] = [];

		if (selectedLocationWithIds?.length !== allLocations.length) {
			skillsReportData.forEach((item: SkillInsightModel) => {
				if (
					selectedLocationWithIds.some((location) =>
						item.locationIds.includes(location.id)
					)
				) {
					const skillData = { ...item };
					skillData.interestedWorkers = item.interestedWorkers.filter(
						(worker) =>
							selectedLocationWithIds.filter(
								(location) => location.id === worker.locationId
							).length !== 0
					);
					skillData.workerCountByLevel = item.workerCountByLevel.filter(
						(worker) =>
							selectedLocationWithIds.filter(
								(location) => location.id === worker.locationId
							).length !== 0
					);
					filtereSkillsdDataByLoc.push(skillData);
				}
			});
		} else {
			filtereSkillsdDataByLoc = [...skillsReportData];
		}

		setOverallSkillsData(filtereSkillsdDataByLoc);
		let interestedWorkersCount = 0;
		filtereSkillsdDataByLoc.forEach((item) => {
			let totalWorkersCount = 0;

			item.workerCountByLevel.forEach((skill, index: number) => {
				if (skill.level !== 0) {
					totalWorkersCount += skill.workersCount;
				}
			});
			if (item.interestedWorkers.length > 0) {
				interestedWorkersCount += item.interestedWorkers.length;
			}
			if (totalWorkersCount < 3) {
				skillsWithLessThanXWorkersData.push(item);
			}
		});
		if (skillsWithLessThanXWorkersData.length === 0) {
			const sortedSkillsData: SkillInsightModel[] = [
				...filtereSkillsdDataByLoc,
			].sort((a, b) => {
				const sumA = a.workerCountByLevel.reduce(
					(acc: any, obj: any) => acc + obj.workersCount,
					0
				);
				const sumB = b.workerCountByLevel.reduce(
					(acc: any, obj: any) => acc + obj.workersCount,
					0
				);
				return sumA - sumB;
			});
			countObj.skillsWithLessThenXWorkersKPI = 0;
			countObj.interestedWorkersKPI = 0;
			setskillsWithLessThanXWorkers(sortedSkillsData);
		} else {
			countObj.skillsWithLessThenXWorkersKPI =
				skillsWithLessThanXWorkersData.length;
			countObj.interestedWorkersKPI = interestedWorkersCount;
			setskillsWithLessThanXWorkers(skillsWithLessThanXWorkersData);
		}

		setKpiCount(countObj);
	}, [skillsReportData, selectedLocations, allLocations, kpiCount]);

	useEffect(() => {
		const workersWithAllSkills: WorkerInsightModel[] = [];
		const countObj = kpiCount;

		if (selectedLocations.length === 0) {
			setWorkersWithAllSkills(workersReportData);
			setOverallWorkersData(workersReportData);
			return;
		}

		let filteredDataByLoc: WorkerInsightModel[] = [];

		if (selectedLocations?.length === allLocations.length) {
			filteredDataByLoc = [...workersReportData];
		} else {
			workersReportData
				.filter((worker) => selectedLocations.includes(worker.locationName))
				.map((item) => {
					const workerData = { ...item };
					workerData.workerBackupList = item.workerBackupList?.filter(
						(worker) => selectedLocations.includes(worker.location)
					);
					filteredDataByLoc.push(workerData);
				});
		}

		setOverallWorkersData(filteredDataByLoc);

		filteredDataByLoc.forEach((worker) => {
			const data = allLocations.find(
				(x: Location) => x.name === worker.locationName
			);

			const skillsDataRequired = skillsReportData.filter(
				(skill: SkillInsightModel) => skill.locationIds.includes(data.id)
			);
			let isWorkerHasAllSkills = skillsDataRequired.length !== 0;

			skillsDataRequired.map((skill: SkillInsightModel) => {
				if (!worker.skillIds.some((s) => s.id === parseInt(skill.skillId))) {
					isWorkerHasAllSkills = false;
				} else {
					const sk = worker.skillIds.find(
						(s) => s.id === parseInt(skill.skillId)
					);
					if (sk && !sk.isQualifiedAsSkilled) {
						isWorkerHasAllSkills = false;
					}
				}
			});
			if (isWorkerHasAllSkills) {
				workersWithAllSkills.push(worker);
			}
		});

		if (workersWithAllSkills.length === 0) {
			const sortedWorkerData: WorkerInsightModel[] = [
				...filteredDataByLoc,
			].sort((a, b) => {
				const sumA = a.workerSkillCountByLevel.reduce(
					(acc: any, obj: any) => acc + obj.count,
					0
				);
				const sumB = b.workerSkillCountByLevel.reduce(
					(acc: any, obj: any) => acc + obj.count,
					0
				);
				return sumB - sumA;
			});
			countObj.workersWithAllSkillsKPI = 0;

			setWorkersWithAllSkills([]);
		} else {
			countObj.workersWithAllSkillsKPI = workersWithAllSkills.length;

			setWorkersWithAllSkills(workersWithAllSkills);
		}
		setKpiCount(countObj);
	}, [
		skillsReportData,
		workersReportData,
		selectedLocations,
		allLocations,
		kpiCount,
	]);

	const priorities = allPriorities.reduce((acc: any, obj: any) => {
		acc[obj.id] = obj;
		return acc;
	}, {});

	return [
		{
			allLocations,
			selectedLocations,
			allSkillLevels,
			workersReportData,
			fetchWorkerInsightReportStatus,
			searchText,
			overAllColumns,
			filteredWorkerSummariesData,
			skillsReportData,
			filteredSkillSummariesData,
			fetchSkillInsightReportStatus,
			isSkillPriorityEnabled,
			skillsWithLessThanXWorkers,
			workersWithLessThenXSkills,
			workersWithAllSkills,
			selectedKPI,
			kpiCount,
			overAllSkillsData,
			overAllWorkersData,
			allPriorities,
			priorities,
		},
		{
			setSelectedLocations,
			setSearchText,
			setOverAllColumns,
			dispatch,
			setFilteredWorkerSummariesData,
			setFilteredSkillSummariesData,
			setKpi,
		},
	];
};

export default useCrossSkillingDashboardController;
