// TODO - Type 'any' needs to be fixed.
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Box, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro-6";
import React from "react";

import useWorkersData from "../../../../controllers/use-workers-data";
import { getCertificationTableRows } from "../../../helper";
import useWorkerCertificationColumn from "../../controllers/use-worker-certification-column";

const NoCertificationsAvailable = () => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<FolderOutlinedIcon
					sx={{ color: "#00000061", height: 30, width: 30 }}
				/>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "14px",
						color: "#00000061",
						lineHeight: "20px",
						letterSpacing: "0.17px",
					}}
				>
					No Certifications added for the Worker
				</Typography>
			</Box>
		</div>
	);
};

interface Job {
	jobId: string;
	jobName: string;
	color: string;
	isExpired: boolean;
	skills: any[];
}

interface WorkerProfile {
	jobs: Job[];
	otherSkills: any[];
	workerCerts: any[];
}

interface WorkerCertificationsTableProps {
	workerProfile: WorkerProfile;
	searchText: string;
}

const WorkerCertificationsTable: React.FC<WorkerCertificationsTableProps> = ({
	workerProfile,
	searchText,
}) => {
	const columns: any = useWorkerCertificationColumn(searchText);
	const { allSkills, deleteCertificationStatus } = useWorkersData();

	const rows = workerProfile?.workerCerts?.map((cert) => ({
		...cert,
		id: cert.certName,
	}));

	const filteredRows = getCertificationTableRows(rows, searchText, allSkills);

	return (
		<Box style={{ height: rows.length > 0 ? "auto" : 300 }}>
			<DataGridPro
				rows={filteredRows}
				columns={columns}
				pagination={rows.length > 0}
				disableColumnSelector
				disableDensitySelector
				disableRowSelectionOnClick
				loading={deleteCertificationStatus === "pending"}
				disableColumnPinning
				slots={{
					noRowsOverlay: NoCertificationsAvailable,
				}}
				initialState={{
					...rows,
					pagination: { paginationModel: { pageSize: 10 } },
					filter: {},
				}}
				pageSizeOptions={[10, 20, 30]}
				//autoHeight
				getRowHeight={() => "auto"}
				sx={{
					position: "sticky",
					border: "none !important",
					".MuiDataGrid-cell": {
						"&:focus": {
							outline: "none",
						},
						"&.hover-column-cell": {
							position: "sticky",
							right: 24,
							zIndex: 1,
						},
						"& .hover-content": {
							transition: "opacity 0.3s ease-in-out",
							opacity: 0,
						},
					},
					".MuiDataGrid-cell:focus-within": {
						outline: "none",
					},
					"& .MuiDataGrid-columnHeader:focus-within": {
						outline: "none",
					},
					".MuiDataGrid-row:hover .hover-column-cell .hover-content": {
						opacity: 1,
						backgroundColor: "#f5f5f5",
					},
				}}
			/>
		</Box>
	);
};

export default WorkerCertificationsTable;
