import BlockIcon from "@mui/icons-material/Block";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { ReactNode, useState } from "react";

import { SkillRepresentationCell } from "../../../../../../../../export/cross-skilling";
import { SkillLevel } from "../../../../../../cross-skilling/types";
import { colors } from "../assets/colors";
import {
  ALPHABET,
  DONUT,
  EMPTY,
  FULL,
  HALF,
  NUMBER,
  QUARTER,
  THREE_QUARTER,
} from "../constants/constants";

const ALPHA_REGEX = /^[a-zA-Z0-9]+$/;

interface ColorInfo {
  colorCode: string;
  colorName: string;
}

interface CrossSkillingLevelsTableRowEditProps {
  skillRepresentationLevels: SkillLevel[];
  level?: number;
  repType?: string;
  repLevel?: string;
  levelCode: string | number;
  icon: string | number;
  changeRepLevel: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  backColorCode?: string;
  levelName: string;
  levelDescription: string;
  isQualifiedAsSkilled: boolean;
  changeLevelName: (event: string) => void;
  changeLevelDescription: (event: string) => void;
  changeBackColorCode: (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => void;
  changeLevelCode: (event: string) => void;
  changeIcon: (event: string) => void;
  changeIsQualifiedAsSkilled: (event: boolean) => void;
  availableColors?: ColorInfo[];
  toBeDeleted?: boolean;
  notAssignedSkillLevel?: SkillLevel;
}

const CrossSkillingLevelsTableRowEdit: React.FC<
  CrossSkillingLevelsTableRowEditProps
> = ({
  skillRepresentationLevels,
  level = 0,
  repType = DONUT,
  repLevel = EMPTY,
  levelCode,
  icon,
  changeRepLevel,
  backColorCode = "",
  levelName,
  levelDescription,
  changeLevelName,
  changeLevelDescription,
  changeBackColorCode,
  changeLevelCode,
  changeIcon,
  availableColors = colors,
  toBeDeleted = false,
  isQualifiedAsSkilled = true,
  changeIsQualifiedAsSkilled,
  notAssignedSkillLevel,
}) => {
  const [iconError, setIconError] = useState("");
  const [levelCodeError, setLevelCodeError] = useState("");
  const [levelNameError, setLevelNameError] = useState("");

  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingTop: "8px",
        paddingBottom: "8px",
        position: "relative",
      }}
    >
      {toBeDeleted && (
        <Grid
          item
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.6)",
            width: "100%",
            height: "100%",
            zIndex: 99999999999, // To make sure it appear on top of any select or text field
          }}
        />
      )}
      <Grid
        item
        xs={1.1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {repType !== ALPHABET && repType !== NUMBER && (
          <Select
            disabled={toBeDeleted}
            id="skill-rep-select"
            displayEmpty
            value={repLevel ?? EMPTY}
            variant="filled"
            onChange={changeRepLevel}
            inputProps={{
              "aria-label": "Without label",
            }}
            renderValue={() => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "10px",
                }}
              >
                <SkillRepresentationCell
                  level={level}
                  representationLevel={repLevel}
                  representationType={repType}
                  height={10}
                  width={10}
                  backgroundWidth={"25px"}
                  backgroundHeight={"25px"}
                />
              </Box>
            )}
          >
            <MenuItem value={EMPTY} key={`${repType}-empty`}>
              <SkillRepresentationCell
                representationLevel={EMPTY}
                representationType={repType}
              />
            </MenuItem>
            <MenuItem value={QUARTER} key={`${repType}-quarter`}>
              <SkillRepresentationCell
                representationLevel={QUARTER}
                representationType={repType}
              />
            </MenuItem>
            <MenuItem value={HALF} key={`${repType}-half`}>
              <SkillRepresentationCell
                representationLevel={HALF}
                representationType={repType}
              />
            </MenuItem>
            <MenuItem value={THREE_QUARTER} key={`${repType}-three-quarter`}>
              <SkillRepresentationCell
                representationLevel={THREE_QUARTER}
                representationType={repType}
              />
            </MenuItem>
            <MenuItem value={FULL} key={`${repType}-full`}>
              <SkillRepresentationCell
                representationLevel={FULL}
                representationType={repType}
              />
            </MenuItem>
          </Select>
        )}
        {(repType === ALPHABET || repType === NUMBER) && (
          <TextField
            disabled={toBeDeleted}
            type={repType === NUMBER ? "number" : "string"}
            inputProps={{
              "aria-label": "Without label",
              maxLength: 2,
              style: {
                padding: 16.5,
                textTransform: "uppercase",
              },
            }}
            value={icon}
            variant="filled"
            sx={{
              width: "60px",
            }}
            onChange={(event) => {
              const value = event.target.value;
              if (
                repType !== NUMBER &&
                value !== "" &&
                !ALPHA_REGEX.test(value)
              ) {
                return;
              }
              const notAssignedSkillValue = notAssignedSkillLevel?.value ?? "";
              const foundIcon =
                skillRepresentationLevels.filter(
                  (level) => level.icon === value
                ) ?? [];
              if (foundIcon.length > 0 || notAssignedSkillValue === value) {
                setIconError("Duplicate Icon");
              } else {
                if (value === "") {
                  setIconError("Empty");
                } else {
                  setIconError("");
                }
              }
              if (value.length < 3) {
                if (repType === NUMBER && parseInt(value) < 0) {
                  setIconError("Empty");
                }
                changeIcon(value);
              }
            }}
            error={iconError.length > 0}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
          />
        )}
      </Grid>
      <Grid
        item
        xs={1.1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Select
          disabled={toBeDeleted}
          id="skill-level-background-color-select"
          inputProps={{
            "aria-label": "Without label",
          }}
          displayEmpty
          value={backColorCode}
          variant="filled"
          onChange={changeBackColorCode}
          renderValue={(value) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "10px",
              }}
            >
              {value === "" && <BlockIcon height={10} width={10} />}
              {value !== "" && (
                <Box
                  sx={{
                    width: "18px",
                    height: "18px",
                    backgroundColor: `#${value}`,
                    border: 1,
                    borderWidth: "1px",
                    borderColor: "rgba(0,0,0,0.4)",
                    borderRadius: "9px",
                  }}
                />
              )}
            </Box>
          )}
        >
          {availableColors.map((colorInfo) => (
            <MenuItem
              value={colorInfo.colorCode}
              key={`${repType}-${colorInfo.colorCode}`}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {colorInfo.colorCode === "" && (
                  <BlockIcon
                    sx={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                )}
                {colorInfo.colorCode !== "" && (
                  <Box
                    sx={{
                      width: "18px",
                      height: "18px",
                      backgroundColor: `#${colorInfo.colorCode}`,
                      borderRadius: "9px",
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.87)",
                    marginLeft: "8px",
                  }}
                >
                  {colorInfo.colorName}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid
        item
        xs={1.06}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <TextField
          disabled={toBeDeleted || repType === ALPHABET || repType === NUMBER}
          type={repType === NUMBER ? "number" : "string"}
          inputProps={{
            "aria-label": "Without label",
            maxLength: 2,
            style: {
              padding: 16.5,
              textTransform: "uppercase",
            },
          }}
          value={levelCode}
          variant="filled"
          sx={{
            width: "60px",
          }}
          onChange={(event) => {
            const value = event.target.value;
            if (value !== "" && !ALPHA_REGEX.test(value)) {
              return;
            }
            const notAssignedSkillValue = notAssignedSkillLevel?.value ?? "";
            const foundLevelCode =
              skillRepresentationLevels.filter(
                (level) => level.value === value
              ) ?? [];
            if (foundLevelCode.length > 0 || notAssignedSkillValue === value) {
              setLevelCodeError("Duplicate Level Code");
            } else {
              setLevelCodeError("");
            }
            if (value.length < 3) {
              changeLevelCode(value);
            }
          }}
          error={
            repType !== ALPHABET && repType !== NUMBER
              ? !String(levelCode).length || levelCodeError.length > 0
              : false
          }
        />
      </Grid>
      <Grid
        item
        xs={2.5}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <TextField
          disabled={toBeDeleted}
          inputProps={{
            "aria-label": "Without label",
            maxLength: 20,
            style: {
              padding: 16.5,
            },
          }}
          value={levelName}
          variant="filled"
          sx={{
            width: "84%",
            marginLeft: "8px",
          }}
          onChange={(event) => {
            const value = event.target.value;
            const notAssignedSkillValue = notAssignedSkillLevel?.name ?? "";
            const foundLevelName =
              skillRepresentationLevels.filter(
                (level) => level.name === value
              ) ?? [];
            if (foundLevelName.length > 0 || notAssignedSkillValue === value) {
              setLevelNameError("Duplicate Level Name");
            } else {
              setLevelNameError("");
            }
            changeLevelName(value);
          }}
          error={!levelName.length || levelNameError.length > 0}
        />
      </Grid>
      <Grid
        item
        xs={3}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
      >
        <TextField
          disabled={toBeDeleted}
          inputProps={{
            "aria-label": "Without label",
            maxLength: 200,
            style: {
              padding: 16.5,
            },
          }}
          value={levelDescription}
          variant="filled"
          sx={{
            width: "93%",
          }}
          onChange={(event) => {
            const value = event.target.value;
            changeLevelDescription(value);
          }}
        />
      </Grid>
      <Grid
        item
        xs={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <SkillRepresentationCell
          level={level}
          representationLevel={repLevel}
          representationType={repType}
          backgroundColor={backColorCode}
          value={String(icon)}
          border={1}
          width={20}
          height={20}
          backgroundWidth={"100%"}
          backgroundHeight={"100%"}
        />
      </Grid>
      <Grid
        item
        xs={2.12}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "100%",
        }}
      >
        <Checkbox
          disabled={toBeDeleted}
          checked={isQualifiedAsSkilled}
          onChange={(event) => changeIsQualifiedAsSkilled(event.target.checked)} // Toggle state
          color="primary"
        />
      </Grid>
    </Grid>
  );
};

export default CrossSkillingLevelsTableRowEdit;
