/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable simple-import-sort/imports */
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import PendingActionsRoundedIcon from "@mui/icons-material/PendingActionsRounded";
import { Stack } from "@mui/material";
import React from "react";
import InsightKpiCard from "../../../insight-dashboard/ui/insight-kpi-card";
import {
	SkillInsightModel,
	WorkerInsightModel,
} from "../../../../../ts/components/insight-dashboard/slices/insight-report-types";
import { useDispatch } from "react-redux";

import { closeSkillInsightDetails } from "../../../../../ts/components/insight-dashboard/slices/skill-insight-report-slice";
import { closeWorkerInsightDetails } from "../../../../../ts/components/insight-dashboard/slices/worker-insight-report-slice";

interface ICrossSkillingDashboardSummariesProps {
	fetchWorkerInsightReportStatus: "idle" | "pending" | "fulfilled" | "error";
	fetchSkillInsightReportStatus: "idle" | "pending" | "fulfilled" | "error";
	skillsWithLessThanXWorkers: SkillInsightModel[];
	workersWithLessThenXSkills: WorkerInsightModel[];
	noOfWorkers: number;
	noOfSkills: number;
	selectedKPI: string;
	setKpi: React.Dispatch<React.SetStateAction<string>>;
	kpiCount: any;
}
const CrossSkillingDashboardSummaries: React.FC<
	ICrossSkillingDashboardSummariesProps
> = ({
	fetchWorkerInsightReportStatus,
	fetchSkillInsightReportStatus,
	skillsWithLessThanXWorkers,
	workersWithLessThenXSkills,
	noOfWorkers,
	noOfSkills,
	selectedKPI,
	setKpi,
	kpiCount,
}) => {
	const dispatch = useDispatch();

	function onHandleKPIClick(actionType: string, data: any) {
		dispatch(closeWorkerInsightDetails());
		dispatch(closeSkillInsightDetails());
		if (selectedKPI === actionType) {
			setKpi("overallWorkers");
		} else {
			setKpi(actionType);
		}
	}

	return (
		<Stack direction={{ xs: "column", sm: "row" }} sx={{ flexWrap: "wrap" }}>
			<InsightKpiCard
				icon={<GroupsRoundedIcon />}
				iconBackgroundColor={"#CCDFFF"}
				iconColor={"#70A6FA"}
				kpiDataCount={kpiCount.workersWithLessThenXSkillsKPI}
				title={"Workers with less than 3 skills"}
				subTitle={`${
					noOfWorkers !== 0
						? Math.floor(
								(kpiCount.workersWithLessThenXSkillsKPI * 100) / noOfWorkers
						  )
						: 0
				}% of your workers`}
				loading={fetchWorkerInsightReportStatus}
				isToggled={selectedKPI === "workersWithLessThenXSkillsKPI"}
				handleClick={onHandleKPIClick}
				onClickActionType={"workersWithLessThenXSkillsKPI"}
				kpiData={workersWithLessThenXSkills}
			/>
			<InsightKpiCard
				icon={<ConstructionRoundedIcon />}
				iconBackgroundColor={"#FFFBA6"}
				iconColor={"#EA783D"}
				kpiDataCount={kpiCount.skillsWithLessThenXWorkersKPI}
				title={"Skills with less than 3 workers"}
				subTitle={`${
					noOfSkills !== 0
						? Math.floor(
								(kpiCount.skillsWithLessThenXWorkersKPI * 100) / noOfSkills
						  )
						: 0
				}% of your skills`}
				loading={fetchSkillInsightReportStatus}
				isToggled={selectedKPI === "skillsWithLessThenXWorkersKPI"}
				handleClick={onHandleKPIClick}
				kpiData={skillsWithLessThanXWorkers}
				onClickActionType={"skillsWithLessThenXWorkersKPI"}
			/>
			<InsightKpiCard
				icon={<GroupsRoundedIcon />}
				iconBackgroundColor={"#CCDFFF"}
				iconColor={"#70A6FA"}
				kpiDataCount={kpiCount.workersWithAllSkillsKPI}
				title={"Workers with all skills"}
				subTitle={`${
					noOfWorkers !== 0
						? Math.floor((kpiCount.workersWithAllSkillsKPI * 100) / noOfWorkers)
						: 0
				}% of your workers`}
				loading={fetchWorkerInsightReportStatus}
				isToggled={selectedKPI === "workersWithAllSkillsKPI"}
				handleClick={onHandleKPIClick}
				onClickActionType={"workersWithAllSkillsKPI"}
				kpiData={workersWithLessThenXSkills}
			/>
			<InsightKpiCard
				icon={<PendingActionsRoundedIcon />}
				iconBackgroundColor={"#FFF2CC"}
				iconColor={"#F99A21"}
				kpiDataCount={kpiCount.interestedWorkersKPI}
				title={"Skills interest pending review"}
				loading={fetchWorkerInsightReportStatus}
				isToggled={selectedKPI === "interestedWorkersKPI"}
				handleClick={onHandleKPIClick}
				onClickActionType={"interestedWorkersKPI"}
			/>
		</Stack>
	);
};
export default CrossSkillingDashboardSummaries;
