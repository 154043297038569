import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkSharpIcon from "@mui/icons-material/LinkSharp";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";

// import { removeDuplicatesById } from "../../../../../utils/helpers";
import useShiftAllocationConfigController from "./controllers/use-shift-allocation-config-controller";
import LocationBasedConfigurationDialog from "./dialogs/location-based-configuration";
import SelectJobsDialog from "./dialogs/select-jobs";
import SeniorityBasedConfigurationDialog from "./dialogs/seniority-based-configuration";
import WorkcenterBasedConfigurationDialog from "./dialogs/workcenter-based-configuration";

const FASTEST =
	"Shift allocation decided by the order workers mark interest on shift.";
const SENIORITY =
	"Shift assigned from most experienced worker to least experienced.";
const WORKCENTER =
	"Shifts are assigned to workers in your team first, then to other teams and so on. ";

const LOCATION =
	"Shifts are first assigned to Flex workers in your team first, then to other FT workers teams and so on.";

interface ShiftAllocationRulesProps {
	locationData: any;
	setLocationData: any;
	creatingNewLocation: boolean;
}

const ShiftAllocationRules = ({
	locationData,
	setLocationData,
	creatingNewLocation,
}: ShiftAllocationRulesProps) => {
	const [
		{
			jobDialogOpen,
			seniorityDialogOpen,
			status,
			errorMessage,
			shiftAllocationRules,
			allJobsByLocation,
			allJobsByLocationObject,
			workcenterDialogOpen,
			locationDialogOpen,
		},
		{
			handleDefaultChange,
			setJobDialogOpen,
			setShiftAllocationRules,
			handleJobRemove,
			openWorkcenterDialog,
			closeWorkcenterDialog,
			openSeniorityDialog,
			closeSeniorityDialog,
			openLocationDialog,
			closeLocationDialog,
		},
	] = useShiftAllocationConfigController({
		locationData,
		setLocationData,
		creatingNewLocation,
	});

	return (
		<Box
			sx={{
				backgroundColor: "#F5F5F5",
				borderRadius: "8px",
				padding: "16px",
			}}
		>
			<Box>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "16px",
						color: "rgba(0, 0, 0, 0.87)",
					}}
				>
					Shift Allocation Engine
				</Typography>
				<Typography
					sx={{
						fontFamily: "Roboto",
						fontWeight: 400,
						fontSize: "14px",
						color: "rgba(0, 0, 0, 0.6)",
					}}
				>
					Configuration around how flex or overtime shifts are allocated to
					workers.
				</Typography>
			</Box>
			<Box mt={2}>
				<Box display='flex'>
					<Box width={250}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Rule
						</Typography>
					</Box>
					<Box width={120} display='flex' alignItems='center'>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Default
						</Typography>
						<Tooltip title='Shifts created by supervisor will follow this allocation rule.'>
							<InfoOutlinedIcon sx={{ marginLeft: "8px", fontSize: "18px" }} />
						</Tooltip>
					</Box>
					<Box width={640}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Associated job roles
						</Typography>
					</Box>
					<Box width={110}>
						<Typography
							sx={{
								fontFamily: "Roboto",
								fontWeight: 400,
								fontSize: "16px",
								color: "rgba(0, 0, 0, 0.87)",
							}}
						>
							Configuration
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box>
					{status === "pending" ? (
						<Box
							height={50}
							display='flex'
							justifyContent='center'
							alignItems='center'
						>
							<CircularProgress />
						</Box>
					) : status === "error" ? (
						<Typography>{errorMessage}</Typography>
					) : shiftAllocationRules?.length === 0 ? (
						<Box
							// height={50}
							display='flex'
							justifyContent='center'
							alignItems='center'
						>
							<Typography> No Rules Found</Typography>
						</Box>
					) : (
						shiftAllocationRules?.map(
							({
								name,
								isDefault,
								isConfigurable,
								jobIds,
								code,
								shiftAllocationTypeId,
							}) => (
								<Box
									key={shiftAllocationTypeId}
									display='flex'
									alignItems='center'
									minHeight={40}
								>
									<Box width={250} display='flex' alignItems='center'>
										<Typography width={200}>{name}</Typography>
										<Tooltip
											title={
												code === "FASTEST"
													? FASTEST
													: code === "SENIORITY"
													? SENIORITY
													: code === "LOCATION"
													? LOCATION
													: WORKCENTER
											}
										>
											<InfoOutlinedIcon
												sx={{ marginLeft: "8px", fontSize: "18px" }}
											/>
										</Tooltip>
									</Box>
									<Box width={110}>
										<Radio
											checked={isDefault}
											onChange={(e) =>
												handleDefaultChange && handleDefaultChange(e, name)
											}
											value={shiftAllocationTypeId}
											name='radio-buttons'
											inputProps={{ "aria-label": "B" }}
										/>
									</Box>
									<Box width={640} display='flex' alignItems='center'>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "25px",
												height: "25px",
												backgroundColor: !isDefault ? "#2f4d8b" : "#aeb9cf",
												borderRadius: "50%",
												cursor: !isDefault ? "pointer" : "default",

												marginRight: "8px",
											}}
											onClick={() =>
												!isDefault &&
												setJobDialogOpen &&
												setJobDialogOpen(shiftAllocationTypeId)
											}
										>
											<LinkSharpIcon
												sx={{ color: "white", fontSize: "20px" }}
											/>
										</Box>
										{jobIds && !isDefault && allJobsByLocationObject ? (
											<Stack
												direction='row'
												flexWrap='wrap'
												alignItems='flex-start'
												width={600}
											>
												{jobIds.split(",").map((job) => (
													<Chip
														key={job}
														label={allJobsByLocationObject[job]?.name ?? ""}
														onDelete={() =>
															handleJobRemove &&
															handleJobRemove(shiftAllocationTypeId, job)
														}
														sx={{ margin: "4px" }}
													/>
												))}
											</Stack>
										) : null}
									</Box>

									<Box>
										{isConfigurable ? (
											<EditIcon
												sx={{
													fontSize: "20px",
													marginLeft: "8px",
													cursor: "pointer",
												}}
												onClick={() => {
													if (code === "SENIORITY") {
														openSeniorityDialog && openSeniorityDialog();
													} else if (code === "LOCATION") {
														openLocationDialog && openLocationDialog();
													} else {
														openWorkcenterDialog && openWorkcenterDialog();
													}
												}}
											/>
										) : null}
									</Box>
								</Box>
							)
						)
					)}
				</Box>
			</Box>
			<SelectJobsDialog
				open={jobDialogOpen !== -1}
				handleClose={() => setJobDialogOpen && setJobDialogOpen(-1)}
				allJobsByLocation={allJobsByLocation}
				allAssigned={true}
				shiftAllocationRules={shiftAllocationRules}
				setShiftAllocationRules={setShiftAllocationRules}
				id={jobDialogOpen}
				allJobsByLocationObject={allJobsByLocationObject}
				setLocationData={setLocationData}
				locationData={locationData}
			/>
			<SeniorityBasedConfigurationDialog
				open={!!seniorityDialogOpen}
				handleClose={() => closeSeniorityDialog && closeSeniorityDialog()}
				shiftAllocationRules={shiftAllocationRules}
				setShiftAllocationRules={setShiftAllocationRules}
				id={2}
				locationData={locationData}
				setLocationData={setLocationData}
			/>
			<WorkcenterBasedConfigurationDialog
				open={!!workcenterDialogOpen}
				handleClose={() => closeWorkcenterDialog && closeWorkcenterDialog()}
				shiftAllocationRules={shiftAllocationRules}
				setShiftAllocationRules={setShiftAllocationRules}
				id={3}
				locationData={locationData}
				setLocationData={setLocationData}
			/>
			<LocationBasedConfigurationDialog
				open={!!locationDialogOpen}
				handleClose={() => closeLocationDialog && closeLocationDialog()}
				shiftAllocationRules={shiftAllocationRules}
				setShiftAllocationRules={setShiftAllocationRules}
				id={4}
				locationData={locationData}
				setLocationData={setLocationData}
			/>
		</Box>
	);
};

export default ShiftAllocationRules;
